<template>
  <div>
    <div class="outer-wrapper">
      <div class="container-fluid">
          <div class="row justify-content-center mob-row">
            <div class="col-sm-12 col-lg-8">
              <div class="col-sm-12  unit-plan-tab-section d-flex">
              <div class="col-lg-5 logo-header">
                <div class="brand-logo-mob text-left">
                  <router-link to="/"><img src="@/assets/images/nr-logo.png" width="100px" height="100px" alt="" class="img-fluid"></router-link>
                </div>
                  <h3>Unit Plan</h3>
              </div>
              <div class="col-lg-7 pr-lg-0 unit-plans-buttons">
                <div class="tabbable boxed parentTabs mt-auto">
                  <ul class="nav nav-pills">
                    <li class="nav-item"><a href="#twodplan" id="twoplan-tab" class="nav-link unit-button active" data-toggle="tab" @click.prevent="toggleTab('#twodplan','#twoplan-tab')">2D Plan</a> </li>
                    <li class="nav-item"><a id="threeplan-tab" href="#threedplan" class="nav-link unit-button" data-toggle="tab"  @click.prevent="toggleTab('#threedplan','#threeplan-tab')">3D Plan</a> </li>
                    <li class="nav-item"><a
                        class="btn btn-interior"
                        @click="openModal('modal-interior')"
                      >Interior Walkthrough</a></li>
                  </ul>
              </div> 
              <img src="@/assets/images/compass.png" width="58px" height="58px" alt="" class="compass-img compass-img-mob">

              </div>               
              </div>
              <div class="plan-holder">
                  <div class="mx-auto text-center floor-map-section w-100">
                    <div id="planTabs" class="mx-auto text-center tab-content">
                    <div class="tab-pane active" id="twodplan">
                    <a :href="selectedFloor.TwoDImg" data-fancybox="gallery" class="block">
                      <img :src="selectedFloor.TwoDImg" alt="2D Plan" class="img-fluid plan-img">
                    </a>
                  </div>
                  <div class="tab-pane" id="threedplan">
                    <a :href="selectedFloor.ThreeDImg" data-fancybox="gallery" class="block">
                      <img :src="selectedFloor.ThreeDImg" alt="3D Plan" class="img-fluid plan-img">
                    </a>
                  </div>
                  </div>
                </div>
                <div class="unit-progress-bar">
                  <ProgressBar :steps="formSteps" :activeStepNo="2"/>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-lg-4 right-container">
              <UnitForm/>
            </div>
          </div>
           <div class="col-12 powered-by powered-by-mob">Powered by <span class="powered-by-ppistol"> PropertyPistol&nbsp;Technologies</span></div>
        <!-- <ArialView />
        <VirtualVisit /> -->

      </div>

    </div>
    <InteriorWalk />
  </div>

</template>
<script>

import {FormSteps} from '@/assets/scripts/utility.js';
import InteriorWalk from "@/components/Plan/InteriorWalk.vue";


export default {
  components: {
    FloorPlanSVG:()=>import("@/components/FloorPlanSVG.vue"),
    UnitForm: ()=>import("@/components/Home/UnitForm.vue"),
    ProgressBar: ()=>import('@/components/ProgressBar.vue'),
    InteriorWalk
  },
  data(){
    return{
      formSteps:FormSteps
    }
  },
  mounted(){
    this.$store.dispatch('setLoading',false);
  },
  computed: {
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    }
  },
  methods: {
    // typologyPlanSrc() {
    //   return require(`@/assets/images/floorplan.jpg`);
    // },
    toggleTab(id,btnId){
      $('#planTabs .tab-pane').removeClass('active');
      $(`${id}`).addClass('active');
      $('.nav-pills .unit-button').removeClass('active');
      $(btnId).addClass('active');

    },
    openModal(modalName) {
      this.$bvModal.show(modalName);
    },
  }
};
</script>
<style scoped>
.events-none{
  pointer-events: none;
}

.plan-img{
  max-width: 450px;
}

.logo-header {
  display: flex;
  position: absolute;
  z-index: 1000;
}

#floor_map{
  width: 100%;
  height: 100%;
}
.mob-show{
  display: none;
}
.outer-wrapper {
  margin: 0;
  background: #f6f6f6;

}

.right-container{
  background: #ffffff;
  padding: 0px 30px;
  position: relative;
  z-index: 2;
}

.title-section{
  display: flex;
}

.unit-plans-buttons{
  display: flex;
  margin-left: auto;
  align-items: flex-end;
}

.unit-plans-buttons .nav.nav-pills{
  margin-top: 30px;
}

.plan-holder {
  border-radius: 10px;
  padding: 0;
  background: transparent;
  padding-bottom: 20px;
}

.tab-content .tab-pane{
  overflow-x: auto;
}

.plan-holder-img{
  max-height: 516px;
}

.compass-img{
  margin-left: auto;
  margin-top: auto;
}

.compass-img-mob{
  display: none;
}


@media (min-width:992px){
  .plan-holder{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
    height: calc(100vh - 170px);
  }
  .unit-progress-bar{
    position: absolute;
    bottom: 5px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.plan-holder h3 {
  font-weight: 600;
  font-size: 18px;
  color: #082D57;
  border-bottom: 2px solid #F5F5F5;
  padding: 20px;
}

.btn-interior{
  background: #0075C4;
  color: #ffffff;
  border-radius: 4px;
  margin-left: 16px;
  height: 40px;
}


.side-menu {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.brand-logo {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

h3 {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  padding: 20px;
  margin: revert;
}

.side-menu.typology {
  height: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px 0px 0px 10px;
  position: relative;
}

.side-menu.typology .list-group {
  flex-direction: column;
}

.side-menu.typology .list-group-item {
  padding: 5px 0;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 15px 15px 15px 40px;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 17px;
  left: 15px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 20px;
  left: 18px;
}

.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-radius: 10px;
}

.prevButton {
  color: #082D57;
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.side-action .btn-primary {
  background: #082D57;
  border-radius: 4px;
  padding: 15px;
}

.side-action p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}
.form-wrapper {
  padding: 0 20px;
}
.form-wrapper  label {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.01em;
  color: #082D57;
  opacity: 0.5;
}
.form-wrapper  select {
  background: url(../assets/images/caret-icon.svg) no-repeat right .75rem center #FFFFFF;
  border: 1px solid #E7EBF0;
  border-radius: 3px;
  padding: 10px;
  appearance: none;
  height: auto;
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
}
.sub-head {
  font-weight: 600;
  font-size: 15px;
  color: #082D57;
  display: block;
}
.card-foot {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
  }
  .card-footer-head span{
    letter-spacing: -0.01em;
    color: #082D57;
    opacity: 0.5;
    font-weight: 500;
    font-size: 12px;
  }
  h5 {
    font-weight: 600;
    font-size: 15px;
    color: #082D57;
  }
.link-btn {
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
}

.unit-button,
.nav-pills .nav-link{
  background: #0075C480;
  color: #ffffff80;
  padding: 8px;
}

.nav-pills .nav-item:first-child .nav-link{
  border-radius: 4px 0px 0px 4px;
}

.nav-pills .nav-item:last-child .nav-link{
  border-radius: 0px 4px 4px 0px;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #0075C4;
  color: #ffffff;
}

.powered-by-mob{
  display: none;
}

.powered-by{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #00000080;
  text-align: center;
  margin-top: 20px;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}

.info-wrapper{
    margin-bottom: 40px;
    background: #fff;
    padding-right: 15px;
    border-radius: 10px;
    display: flex;
 }

  .info-wrapper h2{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
  }

  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 14px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: none;
    opacity: 0.5;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }
@media (min-width: 992px){
  .right-container{
    overflow-y: auto;
    height: 100vh;
  }
}

@media (min-width: 992px) and (max-width: 1400px){
  .right-container{
    padding: 16px;
  }
}

@media (max-width:991px){

  .btn-interior{
    margin: 0;
  }

  .nav-pills .nav-item:last-child{
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 16px;
  }

  .unit-plan-tab-section{
    position: static;
    margin-top: 80px;
  }

  .plan-img{
    max-height: 340px;
  }

  .mob-hide{
    display: none;
  }

  .row.mob-show{
    display: flex;
  }
  .info-wrapper.mob-show{
    padding:104px 20px 30px 5px;
    background: #FFFFFF;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }
  .right-container{
    margin-top: 20px;
    position: static;
    padding: 0;
  }
  .logo-header{
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  }

  .logo-header h3{
    margin: auto;
  }

  .brand-logo-mob{
    position: absolute;
  }

  .powered-by{
    display: none;
  }

  .powered-by.powered-by-mob{
    display: block;
    position: static;
    margin: auto;
    text-align: center;
    transform: none;
    margin-top: 40px;
    margin-bottom: 160px;
  }
  .side-menu{
    min-height: unset;
  }
  .unit-plans-buttons{
    justify-content: center;
  }
  .plan-holder-img{
    width: 100%;
  }
  .plan-holder .tab-content{
    margin-bottom: 20px !important;
  }
  .compass-img{
    display: none;
  }
  .compass-img-mob{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }

}

@media (max-width:576px){
  h3{
    font-size: 16px;
  }
}
</style>